'use client';
import { useEffect } from 'react';

interface AuthAuthProps {
  callbackUrl: string;
}

/**
 * A component leveraged by /auth/signin to automatically authenticate the user once the initial page loads.
 */
const AutoAuth = (props: AuthAuthProps) => {
  const { callbackUrl } = props;

  useEffect(() => {
    const realtimeSignIn = async () => {
      // Import signIn dynamically only at run-time, module fails on build due to missing env variable.
      const { signIn } = await import('next-auth/react');
      signIn('okta', { callbackUrl });
    };

    realtimeSignIn();
  }, []);
  return <></>;
};

export default AutoAuth;
