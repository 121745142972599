import type { InferGetServerSidePropsType } from 'next';
import AutoAuth from '../../../components/Auth/AutoAuth';
import { useRouter } from 'next/router';

const SignIn = ({ providers }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const { callbackUrl } = useRouter().query;
  // Auto auth using client component due to window requirement
  if (providers) {
    return <AutoAuth callbackUrl={callbackUrl as string} />;
  }
};

export async function getServerSideProps() {
  // Import providers dynamically only at run-time, module fails on build due to missing env variable.
  const { getProviders } = await import('next-auth/react');

  const providers = await getProviders();

  return {
    props: { providers: providers ?? [] },
  };
}

export default SignIn;
